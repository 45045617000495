import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { Box, BoxProps, Button, Flex, Heading, Link } from '@/components/atoms';

import { GatsbyPrismicContactBanner } from '@/domain';

interface StaticQuery {
  contactBanner: GatsbyPrismicContactBanner;
}

interface Props extends BoxProps {}

export const ContactBanner = (props: Props) => {
  const data = useStaticQuery<StaticQuery>(contactBannerQuery);
  const heading = data?.contactBanner?.data?.heading?.text || ``;
  const button = {
    label: data?.contactBanner?.data?.button_label || ``,
    path: data?.contactBanner?.data?.button_path || ``,
  };

  return (
    <Flex
      justifyContent="center"
      pt={[10, null, 150]}
      pb={[100, null, 150]}
      {...props}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        backgroundImage={`url(${data?.contactBanner?.data?.image?.gatsbyImageData?.images?.fallback?.src})`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        py={100}
        mx={[10, null, 20, 200]}
        width="100%"
        maxWidth={1000}
        borderRadius="xl"
        position="relative"
        boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 12px"
      >
        <Box
          width="100%"
          height="100%"
          backgroundColor="rgba(255, 255, 255, 0.5)"
          position="absolute"
          top={0}
          left={0}
        />
        <Heading mb={20} zIndex={1} textAlign={[`center`, null, `left`]}>
          {heading}
        </Heading>
        <Link to={`${button.path}`}>
          <Button variant="blue">{button.label}</Button>
        </Link>
      </Flex>
    </Flex>
  );
};

export const contactBannerQuery = graphql`
  {
    contactBanner: prismicContactBanner {
      data {
        heading {
          text
        }
        button_label
        button_path
        image {
          gatsbyImageData(placeholder: BLURRED, width: 800, height: 600)
          alt
        }
      }
    }
  }
`;
