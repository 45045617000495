export function getProjectBackgroundColor(index: number) {
  switch (index) {
    case 0:
      return `brand.newOrange`;
    case 1:
      return `brand.blue`;
    case 2:
      return `brand.newRed`;
    case 3:
      return `brand.newGreen`;
    default:
      return `brand.orange`;
  }
}
