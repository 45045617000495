import React, { useState, useEffect } from 'react';

import { useScroll, useMotionValueEvent, useAnimation } from 'framer-motion';

import {
  Box,
  BoxProps,
  Container,
  Flex,
  Link,
  Logo,
  MotionBox,
  MotionFlex,
  MotionText,
} from '@/components/atoms';

import { _Navigation } from '@/domain';

interface Props extends BoxProps {
  isHome?: boolean;
  navigation: _Navigation[];
}

const maxScrollY = 400;

export const Navigation = (props: Props) => {
  const { isHome = false, navigation } = props;

  const [isAnimated, setIsAnimated] = useState(false);
  const { scrollY } = useScroll();
  const controls = useAnimation();

  useMotionValueEvent(scrollY, `change`, (latest) => {
    if (isHome) {
      if (latest > maxScrollY) {
        setIsAnimated(true);
      } else {
        setIsAnimated(false);
      }
    }
  });

  const navContainerVariants = {
    visible: {
      backdropFilter: `blur(10px)`,
      WebkitBackdropFilter: `blur(10px)`,
      height: 90,
      boxShadow: `0 0 0 1px rgba(0, 0, 0, 0.05)`,
      transition: {
        duration: 0.3,
      },
    },
    hidden: {
      backdropFilter: `blur(0px)`,
      WebkitBackdropFilter: `blur(0px)`,
      height: 140,
      boxShadow: ``,
      transition: {
        duration: 0.3,
      },
    },
  };

  const logoVariants = {
    visible: {
      scale: 0.75,
      transition: {
        duration: 0.3,
      },
    },
    hidden: {
      scale: 1,
      transition: {
        duration: 0.3,
      },
    },
  };

  const renderLinks = () => {
    return navigation?.map((nav, key) => (
      <Box key={key} mr={5}>
        <MotionText
          size="sm"
          fontWeight={600}
          color={!isHome ? `brand.blue` : isAnimated ? `brand.blue` : `white`}
          _hover={{ color: `brand.blue` }}
          sx={{ transition: `0.3s` }}
        >
          <Link to={nav?.path || ``}>{nav?.label}</Link>
        </MotionText>
      </Box>
    ));
  };

  useEffect(() => {
    if (isAnimated) {
      controls.start(`visible`);
    } else {
      controls.start(`hidden`);
    }
  }, [isAnimated]);

  return (
    <MotionFlex
      as="nav"
      alignItems="center"
      width="100%"
      bg="transparent"
      position="fixed"
      top={0}
      animate={isHome ? controls : `visible`}
      variants={navContainerVariants}
      py={4}
      zIndex={799}
      sx={{
        height: isHome ? `auto` : 90,
      }}
    >
      <Container>
        <Flex justifyContent={[`space-between`, null, null, `flex-start`]}>
          <Box as={(props) => <Link to="/" {...props} />}>
            <MotionBox
              animate={controls}
              variants={logoVariants}
              sx={{
                path: {
                  transition: `3s`,
                  fill: !isHome
                    ? `hsl(214.161, 66%, 41%)`
                    : isAnimated
                    ? `hsl(214.161, 66%, 41%)`
                    : `white`,
                },
              }}
            >
              <Logo fill="white" />
            </MotionBox>
          </Box>
          <Flex
            display={[`none`, null, null, `flex`]}
            width="100%"
            alignItems="center"
            pl={10}
          >
            {renderLinks()}
          </Flex>
          {/*<MobileNavigation*/}
          {/*  navigation={navigation}*/}
          {/*  isAnimated={isAnimated}*/}
          {/*  isHome={isHome}*/}
          {/*/>*/}
        </Flex>
      </Container>
    </MotionFlex>
  );
};
