import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';

import { Box, Flex, FlexProps, Link, Text } from '@/components/atoms';

import { _Report } from '@/domain';

require(`dayjs/locale/fr`);

export interface Props extends FlexProps {
  report: _Report;
}

export function Report(props: Props) {
  const { report, ...rest } = props;

  return (
    <Link to={report.file} target="_blank">
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        minHeight={180}
        height="100%"
        borderRadius="xl"
        borderWidth={2}
        borderColor="gray.200"
        py={4}
        px={2}
        cursor="pointer"
        _hover={{
          boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px`,
          WebkitBoxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px`,
          borderColor: `brand.blue`,
        }}
        transition="0.3s"
        {...rest}
      >
        <Box height="100%" mb={4}>
          <Text size="md" textAlign="center">
            {report.heading}
          </Text>
        </Box>
        <Box textAlign="center">
          <Box mb={2}>
            <Text size="sm" textAlign="center">
              {report.date}
            </Text>
          </Box>
          <Box>
            <FontAwesomeIcon
              icon={faCloudArrowDown}
              size="lg"
              color="hsl(214, 20%, 69%)"
            />
          </Box>
        </Box>
      </Flex>
    </Link>
  );
}
