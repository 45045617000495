import React, { forwardRef } from 'react';
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';
import { Link as ChakraLink } from '@chakra-ui/react';
//@ts-ignore
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const LinkComponent = (
  {
    to,
    target,
    type = `link`,
    children,
    ...rest
  }: GatsbyLinkProps<Record<string, unknown>>,
  ref: React.LegacyRef<HTMLAnchorElement>
) => {
  // If external
  if (to.indexOf(`http`) !== -1) {
    return (
      <a
        ref={ref}
        href={to}
        target={target}
        rel="noreferrer noopener"
        {...rest}
      >
        {children}
      </a>
    );
  }

  // If internal
  return (
    <>
      {type === `link` && (
        <ChakraLink
          as={GatsbyLink}
          ref={ref}
          to={to}
          target={target}
          {...rest}
          sx={{ textDecoration: `none !important` }}
        >
          {children}
        </ChakraLink>
      )}
      {type === `animated` && (
        <AniLink
          as={GatsbyLink}
          ref={ref}
          cover
          direction="right"
          bg="hsl(216, 63%, 97%)"
          to={to}
          duration={1}
          {...rest}
        >
          {children}
        </AniLink>
      )}
    </>
  );
};

export const Link = forwardRef(LinkComponent);
