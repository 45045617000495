import { FlexProps } from '@chakra-ui/react';

import React from 'react';

import { MotionValue, useAnimation } from 'framer-motion';

import { MotionFlex, Text } from '@/components/atoms';

interface Props extends FlexProps {
  currentColor: MotionValue<string>;
  pathLength: MotionValue<number>;
  number: number;
  top: number;
  scaleAt: number;
}

export function TimeLineNumber(props: Props) {
  const { currentColor, number, top, pathLength, scaleAt, ...rest } = props;

  const controls = useAnimation();

  pathLength.on(`change`, (path) => {
    if (path > scaleAt) {
      controls.start(`visible`);
    } else {
      controls.start(`hidden`);
    }
  });

  const scaleVariant = {
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        scale: {
          duration: 0.3,
        },
        opacity: {
          duration: 0.2,
        },
      },
    },
    hidden: {
      scale: 0.5,
      opacity: 0,
      transition: {
        delay: 0,
        duration: 0.2,
        opacity: {
          duration: 0.1,
        },
      },
    },
  };

  return (
    //@ts-ignore
    <MotionFlex
      justifyContent="center"
      alignItems="center"
      borderRadius="full"
      position="absolute"
      top={top}
      left={200}
      width={150}
      height={150}
      variants={scaleVariant}
      initial="hidden"
      animate={controls}
      style={{
        backgroundColor: currentColor as any as string,
      }}
      {...rest}
    >
      <Text color="white" fontSize={100} fontWeight={900}>
        {number}
      </Text>
    </MotionFlex>
  );
}
