import React, { useState } from 'react';
import { FlexProps } from '@chakra-ui/react';

import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Image,
  Text,
} from '@/components/atoms';

import { ShapeOneStrokeAnimated } from '@/components/atoms/Icons/ShapeOne';

import { _HomeRegistration } from '@/domain';

export interface Props extends FlexProps {
  registration: _HomeRegistration;
  index: number;
}

export function HomeRegistration(props: Props) {
  const { registration, index, ...rest } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width="100%"
      padding={10}
      borderRadius="xl"
      borderWidth={1}
      borderColor="gray.200"
      transition="0.3s"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      _hover={{
        borderColor:
          index === 0
            ? `brand.newOrange`
            : index === 1
            ? `brand.blue`
            : index === 2
            ? `brand.newRed`
            : `brand.newRed`,
      }}
      position="relative"
      zIndex={40}
      overflow="hidden"
      {...rest}
    >
      <ShapeOneStrokeAnimated
        position="absolute"
        top={-250}
        right={-250}
        animatePathLength={isHovered}
        strokeWidth={100}
        stroke={
          index === 0
            ? `newOrange`
            : index === 1
            ? `blue`
            : index === 2
            ? `newRed`
            : `newRed`
        }
      />
      <ShapeOneStrokeAnimated
        position="absolute"
        bottom={-250}
        left={-250}
        transform="rotate(180deg)"
        animatePathLength={isHovered}
        strokeWidth={100}
        stroke={
          index === 0
            ? `newOrange`
            : index === 1
            ? `blue`
            : index === 2
            ? `newRed`
            : `newRed`
        }
      />
      <Box mb={8} zIndex={10}>
        <Image
          image={registration.icon}
          alt={registration.icon.alt}
          width={50}
          height={50}
        />
      </Box>
      <Box mb={8} zIndex={10}>
        <Heading variant="h5" textAlign="center">
          {registration.heading}
        </Heading>
      </Box>
      <Flex
        justifyContent="center"
        alignItems="center"
        height="100%"
        mb={4}
        sx={{ p: { mb: 2 } }}
        zIndex={10}
      >
        <Text color="gray.600" textAlign="center">
          {registration.description}
        </Text>
      </Flex>
      <Box zIndex={10}>
        <Link to={registration.buttonPath}>
          <Button
            variant={
              index === 0
                ? `newOrange`
                : index === 1
                ? `blue`
                : index === 2
                ? `newRed`
                : `newRed`
            }
          >
            {registration.buttonLabel}
          </Button>
        </Link>
      </Box>
    </Flex>
  );
}
