import React, { useEffect, useState } from 'react';

import { useAnimation } from 'framer-motion';

import {
  Box,
  Link,
  Hamburger,
  MotionBox,
  MotionFlex,
  Text,
} from '@/components/atoms';

import { _Navigation } from '@/domain';

interface Props {
  navigation: _Navigation[];
}

export const MobileNavigation = (props: Props) => {
  const { navigation } = props;
  const [isOpen, setIsOpen] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    if (isOpen) {
      controls.start(`visible`);
      document.body.style.overflow = `hidden`;
    } else {
      controls.start(`hidden`);
      document.body.style.overflow = `visible`;
    }
  }, [isOpen]);

  const variants = {
    visible: {
      width: `110vw`,
      height: `110vh`,
      bottom: 0,
      right: 0,
      borderRadius: 0,
      backgroundColor: `hsl(214.161, 66%, 41%)`,
      transition: {
        duration: 0.5,
        ease: [0.645, 0.045, 0.355, 1],
      },
    },
    hidden: {
      width: `initial`,
      height: `initial`,
      bottom: 40,
      right: 40,
      borderRadius: 30,
      backgroundColor: `hsl(214.161, 66%, 41%)`,
      transition: {
        duration: 0.5,
        ease: [0.645, 0.045, 0.355, 1],
      },
    },
  };

  const hamburgerVariants = {
    visible: {
      x: -40,
      y: -40,
      borderRadius: 999,
      transition: {
        duration: 0.5,
        ease: [0.645, 0.045, 0.355, 1],
      },
    },
    hidden: {
      x: 0,
      y: 0,
      borderRadius: 999,
      transition: {
        duration: 0.5,
        ease: [0.645, 0.045, 0.355, 1],
      },
    },
  };

  const contentVariants = {
    visible: {
      y: 0,
      transition: {
        delay: 0.3,
        duration: 0,
      },
    },
    hidden: {
      y: `-200%`,
      transition: {
        delay: 0.3,
        duration: 0,
      },
    },
  };

  const linkVariants = {
    visible: (key: number) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.3 + key * 0.05,
        duration: 0.1,
      },
    }),
    hidden: {
      y: -20,
      opacity: 0,
      transition: {
        delay: 0,
        duration: 0.1,
      },
    },
  };

  const renderLinks = () => {
    return navigation?.map((nav, key) => (
      <MotionBox
        key={key}
        mb={5}
        custom={key}
        variants={linkVariants}
        initial="hidden"
        animate={controls}
      >
        <Text
          size="xl"
          fontWeight={600}
          color="white"
          _hover={{ color: `brand.newRed` }}
          sx={{ transition: `0.2s` }}
          textAlign="center"
        >
          <Link to={nav?.path || ``}>{nav?.label}</Link>
        </Text>
      </MotionBox>
    ));
  };

  return (
    <>
      <MotionFlex
        width={300}
        height={450}
        display={[`flex`, null, null, `none`]}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="fixed"
        left="calc(50% - 150px)"
        top="calc(50% - 225px)"
        zIndex={801}
        variants={contentVariants}
        initial="hidden"
        animate={controls}
      >
        {renderLinks()}
      </MotionFlex>
      <Box
        display={[`block`, null, null, `none`]}
        position="fixed"
        bottom={10}
        right={10}
        bg="brand.blue"
        zIndex={800}
      >
        <MotionFlex
          position="fixed"
          bottom={10}
          right={10}
          justifyContent="flex-end"
          alignItems="flex-end"
          width="auto"
          borderRadius={30}
          variants={variants}
          initial="hidden"
          animate={controls}
          zIndex={802}
          {...props}
        >
          <MotionBox
            borderRadius="full"
            overflow="hidden"
            cursor="pointer"
            height="max-content"
            padding={2}
            onClick={() => setIsOpen(!isOpen)}
            variants={hamburgerVariants}
            initial="hidden"
            animate={controls}
            zIndex={803}
          >
            <Hamburger isOpen={isOpen} isAnimated={false} />
          </MotionBox>
        </MotionFlex>
      </Box>
    </>
  );
};
