import React, { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
  in: boolean;
}

export const Conditional = (props: Props): JSX.Element => {
  return props.in ? <>{props.children}</> : <></>;
};
