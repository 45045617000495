import React from 'react';
import styled from '@emotion/styled';
import Select, { Props as ReactSelectprops } from 'react-select';

interface Props extends ReactSelectprops {
  options: {
    label: string;
    value: string;
  }[];
  value: {
    label: string;
    value: string;
  };
}

const SelectRoot = styled(Select)`
  font-family: 'Biennale', sans-serif !important;
  color: ${({ theme }) => theme.colors.brand.blue};

  .css-ztxsyp {
    transition: 0.5s;
    padding: 5px 10px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.brand.blue};
      color: white;
    }
  }

  span.css-1u9des2-indicatorSeparator {
    display: none;
  }

  .css-1xc3v61-indicatorContainer svg {
    fill: ${({ theme }) => theme.colors.brand.blue};
  }

  .css-hlgwow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
  }
`;

const customStyles = {
  option: () => ({
    _hover: `background-color: red;`,
    cursor: `pointer`,
  }),
  control: () => ({
    border: `1px solid hsl(214.161, 66%, 41%)`,
    display: `flex`,
    cursor: `pointer`,
    borderRadius: 5,
  }),
  singleValue: () => {
    const cursor = `pointer`;
    const transition = `opacity 300ms`;

    return { cursor, transition };
  },
};

export const SelectComponent = (props: Props): JSX.Element => {
  //@ts-ignore
  return <SelectRoot styles={customStyles} isSearchable={false} {...props} />;
};
