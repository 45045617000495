import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import {
  Box,
  BoxProps,
  Container,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  RichText,
  Text,
} from '@/components/atoms';

import { _Image, GatsbyPrismicLayout } from '@/domain';

interface StaticQuery {
  footer: GatsbyPrismicLayout;
  heart: {
    childImageSharp: _Image;
  };
}

interface Props extends BoxProps {}

export const Footer = (props: Props) => {
  const data = useStaticQuery<StaticQuery>(footerQuery);

  const navigation = data.footer.data.navigation;
  const about = data.footer.data.about_us?.text || ``;
  const contactInformations = data.footer.data.contact_informations;

  function renderLinks() {
    return navigation?.map((nav, key) => (
      <Box key={key}>
        <Text
          variant="sm"
          fontWeight={600}
          color="white"
          _hover={{ color: `brand.red` }}
          sx={{ transition: `0.3s` }}
        >
          <Link to={nav?.path || ``}>{nav?.label}</Link>
        </Text>
      </Box>
    ));
  }

  function renderContactInformations() {
    return contactInformations?.map((item, key) => (
      <Box key={key}>
        <Flex
          flexWrap="wrap"
          alignItems="center"
          mb={4}
          sx={{
            color: ` white`,
            fontWeight: 600,
            a: { textDecoration: `none` },
          }}
        >
          <Image
            image={item?.icon?.gatsbyImageData}
            alt={item?.icon?.gatsbyImageData.alt}
            width={5}
            height={5}
            mr={2}
          />
          <RichText render={item?.text?.richText} />
        </Flex>
      </Box>
    ));
  }

  return (
    <Flex flexDirection="column" bg="brand.blue" {...props}>
      <Container>
        <Grid columns={10} py={100} gap={[0, null, null, 10]}>
          <Box
            gridColumn={[`span 10`, null, null, `span 3`]}
            mb={[10, null, null, 0]}
          >
            <Heading color="brand.white" mb={[5, null, null, 10]}>
              Contact
            </Heading>
            <Box>{renderContactInformations()}</Box>
          </Box>
          <Box
            gridColumn={[`span 10`, null, null, `span 5`]}
            mb={[10, null, null, 0]}
          >
            <Heading color="brand.white" mb={[5, null, null, 10]}>
              À propos
            </Heading>
            <Box>
              <Text color="white" fontWeight={600}>
                {about}
              </Text>
            </Box>
          </Box>
          <Box
            gridColumn={[`span 10`, null, null, `span 2`]}
            mb={[10, null, null, 0]}
          >
            <Heading color="brand.white" mb={[5, null, null, 10]}>
              Pages
            </Heading>
            <Box>{renderLinks()}</Box>
          </Box>
        </Grid>
      </Container>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        bg="brand.blue"
      >
        <Box width="70%" height="0.5px" bg="white" />
        <Box py={10}>
          <Text color="white" mb={4}>
            © École Notre Dame La Hulpe {new Date().getFullYear()}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export const footerQuery = graphql`
  {
    footer: prismicLayout {
      data {
        navigation {
          label
          path
        }
        about_us {
          text
        }
        contact_informations {
          icon {
            gatsbyImageData(placeholder: BLURRED, width: 50, height: 40)
            alt
          }
          text {
            richText
          }
        }
      }
    }
  }
`;
