import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  field: {
    borderWidth: 1,
    transition: `0.3s`,
    _focus: {
      borderWidth: 1,
      borderColor: `hsl(5, 29%, 74%)`,
    },
  },
};

const sizes: SystemStyleObject = {
  sm: {
    field: {
      h: 10,
    },
  },
  md: {
    field: {
      h: 16,
    },
  },
};

const defaultProps: SystemStyleObject = {
  size: `sm`,
};

export const Input = {
  parts: [`addon`, `field`, `element`],
  baseStyle,
  sizes,
  defaultProps,
};
