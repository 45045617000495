import React, { forwardRef } from 'react';
import {
  Heading as ChakraHeading,
  HeadingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

export interface Props extends HeadingProps {}

export const Heading = forwardRef<HTMLHeadingElement, Props>(
  (props, ref): JSX.Element => {
    const styles = useStyleConfig(`Heading`, props);
    return <ChakraHeading ref={ref} __css={styles} {...props} />;
  }
);

export const MotionHeading = motion(Heading);
