import { breakpoints, colors, typography } from '@/theme/foundations';
import {
  Button,
  Heading,
  Input,
  Link,
  Textarea,
  Text,
  Accordion,
} from '@/theme/components';

export const theme = {
  styles: {
    global: {
      body: {
        fontFamilly: `Biennale, sans-serif`,
      },
    },
  },
  ...colors,
  ...typography,
  ...breakpoints,
  components: {
    Accordion,
    Button,
    Heading,
    Input,
    Link,
    Text,
    Textarea,
  },
};
