import React, { forwardRef } from 'react';
import {
  chakra,
  Textarea as ChakraTextarea,
  TextareaProps,
  useStyleConfig,
} from '@chakra-ui/react';

import { Label } from '../Label';

export interface Props extends TextareaProps {
  label?: string;
  name?: string;
}

const InputLabel = chakra(Label, {});

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  (props, ref): JSX.Element => {
    const styles = useStyleConfig(`Textarea`, props);

    return (
      <>
        {props.label && <InputLabel mb={1}>{props.label}</InputLabel>}
        <ChakraTextarea __css={styles} ref={ref} borderRadius={5} {...props} />
      </>
    );
  }
);
