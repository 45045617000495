import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  _hover: {
    // color: `teal.400`,
    textDecoration: `none`,
  },
};

const defaultProps: SystemStyleObject = {
  variant: `default`,
};

export const Link = {
  baseStyle,
  defaultProps,
};
