import React, { forwardRef } from 'react';
import { Spinner as ChakraSpinner, SpinnerProps } from '@chakra-ui/react';

export interface Props extends SpinnerProps {}

export const Spinner = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    return <ChakraSpinner ref={ref} {...props} />;
  }
);
