import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  borderWidth: 1,
  transition: `0.5s`,
  _focus: {
    borderWidth: 1,
    borderColor: `blue.600`,
  },
};

const defaultProps: SystemStyleObject = {
  size: `sm`,
};

export const Textarea = {
  parts: [`addon`, `field`, `element`],
  baseStyle,
  defaultProps,
};
