import React from 'react';

import { MotionValue } from 'framer-motion';

import { Flex, FlexProps } from '@/components/atoms';
import { HomeProject, HomeProjectMobile } from '@/components/molecules';

import { getProjectBackgroundColor } from '@/utils';

import { _HomeProject } from '@/domain';

interface Props extends FlexProps {
  index: number;
  project: _HomeProject;
  pathLength: MotionValue<number>;
}

const scaleAt = [0.18, 0.4, 0.6, 0.83];

export const HomeProjectsLine = (props: Props) => {
  const { index, project, pathLength, ...rest } = props;

  return (
    <Flex
      className="home-projects-line"
      width="100%"
      justifyContent={[`center`, null, null, `flex-end`]}
      mb={[10, null, null, 20]}
      gap={[0, null, null, 20]}
      _hover={{
        '.home_project_text_container': {
          backgroundColor: `transparent`,
        },
        '.home_project_text_container > .home_project_icons': {
          backgroundColor: `white`,
          transform: `translateY(-10px)`,
          boxShadow: `rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px`,
        },
        '.home_project_icon': {
          opacity: 0,
        },
        '.home_project_icon_coloured': {
          opacity: 1,
        },
        '.home_project_text': {
          color: `white`,
        },
        '.home_project_buttons > div:nth-of-type(1)': {
          opacity: 1,
          zIndex: 3,
        },
        '.home_project_buttons > div:nth-of-type(2)': {
          opacity: 0,
        },
      }}
      zIndex={1}
      {...rest}
    >
      <HomeProject
        width={[`100%`, `100%`, `80%`, `50%`]}
        project={project}
        backgroundColor={getProjectBackgroundColor(index)}
        pathLength={pathLength}
        scaleAt={scaleAt[index]}
      />
      <HomeProjectMobile
        width={[`100%`, `100%`, `80%`, `50%`]}
        project={project}
        backgroundColor={getProjectBackgroundColor(index)}
      />
    </Flex>
  );
};
