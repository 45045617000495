import React, { forwardRef } from 'react';
import {
  Button as ChakraButton,
  ButtonProps,
  useStyleConfig,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useTheme } from '@emotion/react';

import { Spinner } from '../Spinner';
import { Box } from '../Box';

export type ButtonVariants =
  | `white`
  | `black`
  | `green`
  | `newGreen`
  | `red`
  | `newRed`
  | `blue`
  | `orange`
  | `newOrange`
  | `darkOrange`;

export interface Props extends ButtonProps {
  icon?: IconProp;
  iconColor?: string;
  loading?: boolean;
  spinAnimation?: boolean;
}

const ChakraButtonRoot = styled(ChakraButton)`
  transition: all 0.5s ease-Out;

  &:hover #spin {
    width: 100%;
    height: 900%;
    opacity: 1;
    left: 0%;
    top: -400%;
    transform: rotate(80deg);
    color: white;
  }
`;

const Spin = styled(Box)`
  width: 0px;
  height: 0px;
  opacity: 0;
  left: 50%;
  top: 45%;
  transform: rotate(0deg);
  background: none;
  position: absolute;
  transition: all 0.5s ease-Out;
`;

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ spinAnimation = true, ...props }, ref) => {
    const styles = useStyleConfig(`Button`, props);
    const theme = useTheme();

    const animationProps = (variant: ButtonVariants) => {
      switch (variant) {
        case `orange`:
          return {
            //@ts-ignore
            bg: `${theme.colors.brand.orange}`,
            color: `white`,
          };
        case `newOrange`:
          return {
            //@ts-ignore
            bg: `${theme.colors.brand.newOrange}`,
            color: `white`,
          };
        case `darkOrange`:
          return {
            //@ts-ignore
            bg: `${theme.colors.brand.darkOrange}`,
            color: `white`,
          };
        case `green`:
          return {
            //@ts-ignore
            bg: `${theme.colors.brand.green}`,
            color: `white`,
          };
        case `newGreen`:
          return {
            //@ts-ignore
            bg: `${theme.colors.brand.newGreen}`,
            color: `white`,
          };
        case `red`:
          return {
            //@ts-ignore
            bg: `${theme.colors.brand.red}`,
            color: `white`,
          };
        case `newRed`:
          return {
            //@ts-ignore
            bg: `${theme.colors.brand.newRed}`,
            color: `white`,
          };
        case `black`:
          return {
            bg: `black`,
            color: `white`,
          };
        case `blue`:
          return {
            //@ts-ignore
            bg: `${theme.colors.brand.blue}`,
            color: `white`,
          };
        case `white`:
          return {
            bg: `rgba(255, 255, 255, 0.4)`,
            color: `white`,
          };
        default:
          return {
            bg: `rgba(255, 255, 255, 0.4)`,
            color: `none`,
          };
      }
    };

    const animationStyle = animationProps(props.variant as ButtonVariants);

    return (
      <ChakraButtonRoot
        ref={ref}
        __css={styles}
        rightIcon={
          props.icon && (
            <FontAwesomeIcon
              size="xs"
              color={props.iconColor}
              icon={props.icon}
            />
          )
        }
        sx={{
          overflow: `hidden !important`,
          '&:hover': {
            color: spinAnimation ? animationStyle.color : `none`,
          },
          '&:hover #spin': {
            bg: spinAnimation ? animationStyle.bg : `none`,
          },
        }}
        position="relative"
        {...props}
      >
        {props.loading && <Spinner size={props.size} />}
        <Box position="relative" zIndex={1}>
          {!props.loading && props.children}
        </Box>
        <Spin id="spin" zIndex={0} />
      </ChakraButtonRoot>
    );
  }
);
