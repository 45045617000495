import React, { useRef } from 'react';

import { useInView } from 'framer-motion';

import { FlexProps } from '@chakra-ui/react';

import {
  AnimatedBox,
  Flex,
  Heading,
  Image,
  ShapeOne,
  Text,
} from '@/components/atoms';

import { getBackgroundColor } from '@/utils';

import { _ExtraSection } from '@/domain';

interface Props extends FlexProps {
  index: number;
  extraSection: _ExtraSection;
}

export function ExtraSection(props: Props) {
  const { index, extraSection, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { amount: 0.3, once: true });

  return (
    <Flex
      ref={ref}
      key={index}
      flexDirection={[`column`, null, index % 2 === 0 ? `row` : `row-reverse`]}
      justifyContent="space-between"
      alignItems={[`center`, null, `flex-start`]}
      mb={[100, null, null, 200]}
      {...rest}
    >
      <Flex
        width={[270, 350, 450, 450]}
        height={[180, 230, 320, 320]}
        position="relative"
        justifyContent="center"
        mr={[0, null, 10]}
        mb={[20, null, 0]}
      >
        <AnimatedBox animate={isInView} scaleHidden={0.9} yHidden={0.1}>
          <Image
            image={extraSection.image}
            alt={extraSection.image.alt}
            borderRadius="lg"
          />
        </AnimatedBox>
      </Flex>
      <Flex
        width={[`100%`, null, `50%`]}
        flexDirection="column"
        alignItems={[`center`, null, `flex-start`]}
        pr={[0, null, 10]}
        position="relative"
      >
        <AnimatedBox
          animate={isInView}
          direction={index % 2 === 0 ? `left` : `right`}
        >
          <Heading variant="h2" mb={5} textAlign={[`center`, null, `left`]}>
            {extraSection.heading}
          </Heading>
        </AnimatedBox>
        <AnimatedBox
          animate={isInView}
          direction={index % 2 === 0 ? `left` : `right`}
          delay={0.3}
        >
          <Text mb={10} textAlign={[`center`, null, `left`]}>
            {extraSection.description}
          </Text>
        </AnimatedBox>
        <AnimatedBox
          animate={isInView}
          yHidden={0.1}
          scaleHidden={0.1}
          delay={0.5}
          duration={0.3}
          position="absolute"
          top={index % 2 === 0 ? [0, null, null, -50] : [0, null, null, -70]}
          right={index % 2 === 0 ? [-200, null, null, -250] : `initial`}
          left={index % 2 !== 0 ? [-200, null, null, -250] : `initial`}
          zIndex={-1}
        >
          <ShapeOne
            width={[400, null, null, 500]}
            height={[400, null, null, 500]}
            fill={getBackgroundColor(index)}
          />
        </AnimatedBox>
      </Flex>
    </Flex>
  );
}
