import React from 'react';

import { BoxProps } from '@chakra-ui/react';

import { Box, Conditional, Flex, Heading, Text } from '@/components/atoms';

import { _Teacher } from '@/domain';

interface Props extends BoxProps {
  teacher: _Teacher;
}

export function Teacher(props: Props) {
  const { teacher, ...rest } = props;
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      borderWidth={1}
      borderColor="gray.100"
      minHeight={500}
      borderRadius="lg"
      px={5}
      py={[10, 5, null, 10]}
      boxShadow="none"
      transition="0.3s"
      _hover={{
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
      }}
      {...rest}
    >
      <Flex
        position="relative"
        justifyContent="center"
        alignItems="center"
        width={120}
        height={120}
        bg="gray.100"
        borderRadius="full"
        borderWidth={5}
        borderColor="brand.white"
        overflow="hidden"
        mb={10}
        backgroundImage={
          //@ts-ignore
          teacher.image?.gatsbyImageData !== null
            ? //@ts-ignore
              `url(${teacher.image.gatsbyImageData.images.fallback.src})`
            : ``
        }
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        // zIndex={2}
        // position="absolute"
        // top={0}
        // left={0}
        // animate={{
        //   scale: isInView ? 1 : 0.7,
        //   opacity: isInView ? 1 : 0,
        // }}
        sx={{ zIndex: `2 !important` }}
      >
        {/*//@ts-ignore*/}
        <Conditional in={teacher.image?.gatsbyImageData === null}>
          <Box>
            <Heading variant="h4">
              {teacher.name.length > 0 ? teacher?.name?.charAt(0) : ``}
            </Heading>
          </Box>
        </Conditional>
      </Flex>
      <Flex flexDirection="column" alignItems="center">
        <Box mb={4}>
          <Text textAlign="center" fontWeight="semibold" size="xl">
            {teacher.name}
          </Text>
        </Box>
        <Box mb={4}>
          <Text textAlign="center" fontWeight="semibold">
            {teacher.function}
          </Text>
        </Box>
        <Box>
          <Text textAlign="center">{teacher.description}</Text>
        </Box>
      </Flex>
    </Flex>
  );
}
