import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  fontFamily: `Quicksand`,
};

const sizes: SystemStyleObject = {
  xl: {
    fontSize: `text.1`,
  },
  lg: {
    fontSize: `text.2`,
  },
  md: {
    fontSize: `text.3`,
  },
  sm: {
    fontSize: `text.4`,
  },
  xs: {
    fontSize: `text.5`,
  },
};

const defaultProps: SystemStyleObject = {
  size: `md`,
};

export const Text = {
  baseStyle,
  sizes,
  defaultProps,
};
