import { SystemStyleObject } from '@chakra-ui/react';

import { ButtonVariants } from '@/components/atoms';

const baseStyle: SystemStyleObject = {
  borderRadius: 7,
  fontWeight: `bold`,
  border: `2px solid`,
};

const variants: Record<ButtonVariants, SystemStyleObject> = {
  green: {
    color: `brand.green`,
    background: `transparent`,
    borderColor: `brand.green`,
  },
  newGreen: {
    color: `brand.newGreen`,
    background: `transparent`,
    borderColor: `brand.newGreen`,
  },
  orange: {
    color: `brand.orange`,
    background: `transparent`,
    borderColor: `brand.orange`,
  },
  newOrange: {
    color: `brand.newOrange`,
    background: `transparent`,
    borderColor: `brand.newOrange`,
  },
  red: {
    color: `brand.red`,
    background: `transparent`,
    borderColor: `brand.red`,
  },
  newRed: {
    color: `brand.newRed`,
    background: `transparent`,
    borderColor: `brand.newRed`,
  },
  blue: {
    color: `brand.blue`,
    background: `transparent`,
    borderColor: `brand.blue`,
  },
  black: {
    color: `black`,
    backgroundColor: `transparent`,
    borderColor: `black`,
  },
  white: {
    color: `white`,
    backgroundColor: `transparent`,
    borderColor: `white`,
  },
};

const defaultProps: SystemStyleObject = {};

export const Button = {
  baseStyle,
  variants,
  defaultProps,
};
