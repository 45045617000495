import React, { forwardRef, ReactNode } from 'react';
import {
  chakra,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
  useStyleConfig,
} from '@chakra-ui/react';

import { Label } from '../Label';

export type InputType = `text` | `email` | `tel`;

export interface Props extends InputProps {
  label?: string;
  name?: string;
  rightIcon?: ReactNode;
  rightIconColor?: string;
  leftIcon?: ReactNode;
  leftIconColor?: string;
}

const InputLabel = chakra(Label, {});

export const Input = forwardRef<HTMLInputElement, Props>(
  (props, ref): JSX.Element => {
    const styles = useStyleConfig(`Input`, props);

    return (
      <>
        {props.label && <InputLabel mb={1}>{props.label}</InputLabel>}
        <InputGroup>
          {props.leftIcon && (
            <InputLeftElement
              pointerEvents="none"
              fontSize={`0.7rem`}
              children={props.leftIcon}
              color={props.leftIconColor}
            />
          )}
          <ChakraInput __css={styles} ref={ref} borderRadius={5} {...props} />
          {props.rightIcon && (
            <InputRightElement
              pointerEvents="none"
              fontSize={`0.7rem`}
              children={props.rightIcon}
              color={props.rightIconColor}
            />
          )}
        </InputGroup>
      </>
    );
  }
);
