import { useCallback } from 'react';

interface DocType {
  type: string;
  uid: string;
  lang: string;
}

const useLinkResolver = () => {
  const match = useCallback((doc: DocType) => {
    if (doc?.type === `home_page`) {
      return `/`;
    }

    return `/${doc?.uid}`;
  }, []);

  return (doc: DocType) => match(doc) as string;
};

export default useLinkResolver;
