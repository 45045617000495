export function getBackgroundColor(index: number) {
  switch (index) {
    case 0:
      return `newOrange`;
    case 1:
      return `blue`;
    case 2:
      return `newRed`;
    case 3:
      return `newGreen`;
    default:
      return `orange`;
  }
}
