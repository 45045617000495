import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { BoxProps, MotionBox } from '../Box';

export interface Props extends BoxProps {
  children: ReactNode;
  animate?: boolean;
  vertical?: boolean;
  duration?: number;
  delay?: number;
  scaleHidden?: number;
  xHidden?: number | string;
  yHidden?: number | string;
  direction?: `top` | `right` | `left` | `bottom`;
}

const Root = styled(MotionBox)``;

export const AnimatedBox = ({
  children,
  animate = false,
  direction = `bottom`,
  duration,
  delay,
  scaleHidden,
  yHidden,
  xHidden,
  ...props
}: Props): JSX.Element => {
  const variantTop = {
    hidden: {
      y: yHidden || 50,
      scale: scaleHidden || 1,
      opacity: 0,
      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        damping: 12,
        stiffness: 200,
      },
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        y: {
          duration: duration || 0.85,
          delay: delay || 0,
        },
        opacity: {
          duration: duration || 0.85,
          delay: delay || 0,
        },
        scale: {
          duration: duration || 0.85,
          delay: delay || 0,
        },
        type: `spring`,
        damping: 12,
        stiffness: 200,
      },
    },
  };

  const variantRight = {
    hidden: {
      x: xHidden || -50,
      opacity: 0,
      scale: scaleHidden || 1,
      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        type: `spring`,
        damping: 12,
        stiffness: 200,
      },
    },
    visible: {
      x: 0,
      opacity: 1,
      scale: 1,
      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        x: {
          duration: duration || 0.85,
          delay: delay || 0,
        },
        opacity: {
          duration: duration || 0.85,
          delay: delay || 0,
        },
        scale: {
          duration: duration || 0.85,
          delay: delay || 0,
        },
        type: `spring`,
        damping: 12,
        stiffness: 200,
      },
    },
  };

  const variantBottom = {
    hidden: {
      y: yHidden || -50,
      scale: scaleHidden || 1,
      opacity: 0,
      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        // type: 'spring',
        // damping: 12,
        // stiffness: 200,
      },
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        y: {
          duration: duration || 0.85,
          delay: delay || 0,
        },
        opacity: {
          duration: duration || 0.85,
          delay: delay || 0,
        },
        scale: {
          duration: duration || 0.85,
          delay: delay || 0,
        },
        type: `spring`,
        damping: 12,
        stiffness: 200,
      },
    },
  };

  const variantLeft = {
    hidden: {
      x: xHidden || 50,
      opacity: 0,
      scale: scaleHidden || 1,
      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        delay: 5,
        type: `spring`,
        damping: 15,
        stiffness: 100,
      },
    },
    visible: {
      x: 0,
      opacity: 1,
      scale: 1,
      transition: {
        x: {
          duration: duration || 0.55,
          delay: delay || 0,
        },
        opacity: {
          duration: duration || 0.55,
          delay: delay || 0,
        },
        scale: {
          duration: duration || 0.85,
          delay: delay || 0,
        },
        ease: [0.455, 0.03, 0.515, 0.955],
        type: `spring`,
        damping: 15,
        stiffness: 100,
      },
    },
  };

  const variants = (direction: string) => {
    switch (direction) {
      case `top`:
        return variantTop;
      case `right`:
        return variantRight;
      case `bottom`:
        return variantBottom;
      case `left`:
        return variantLeft;
      default:
        return variantTop;
    }
  };

  return (
    //@ts-ignore
    <Root
      initial="hidden"
      animate={animate ? `visible` : `hidden`}
      variants={variants(direction)}
      {...props}
    >
      {children}
    </Root>
  );
};
