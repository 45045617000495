import React, { FormHTMLAttributes, ReactNode, forwardRef } from 'react';

export interface Props extends FormHTMLAttributes<HTMLFormElement> {
  children: ReactNode;
  name: string;
}

export const Form = forwardRef<HTMLFormElement, Props>(
  (props, ref): JSX.Element => {
    return (
      <form ref={ref} {...props}>
        {props.children}
      </form>
    );
  }
);
