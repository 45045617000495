import React, { ReactNode } from 'react';
import { chakra } from '@chakra-ui/react';

export interface Props {
  children: ReactNode;
}
const LabelRoot = chakra(`label`, {
  baseStyle: {
    display: `block`,
    fontSize: `1rem`,
    fontWeight: `bold`,
    color: `gray.400`,
  },
});

export const Label = (props: Props): JSX.Element => {
  return <LabelRoot {...props}>{props.children}</LabelRoot>;
};
