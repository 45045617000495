import React from 'react';

type LogoProps = {
  width?: number;
  height?: number;
  fill?: string;
};

export function Logo(props: LogoProps) {
  const { width, height, fill, ...rest } = props;
  return (
    <svg
      width={width || 80}
      height={height || 80}
      viewBox="0 0 499.000000 499.000000"
      preserveAspectRatio="xMidYMid meet"
      style={{ transition: `0.3s` }}
      {...rest}
    >
      <g
        transform="translate(0.000000,499.000000) scale(0.100000,-0.100000)"
        fill={fill || `#000000`}
        stroke="none"
      >
        <path
          d="M2265 4698 c-411 -46 -830 -222 -1155 -485 -100 -81 -291 -279 -364
-376 -235 -316 -382 -673 -431 -1050 -19 -152 -19 -432 0 -584 49 -377 196
-734 431 -1050 73 -97 264 -295 364 -376 544 -440 1262 -600 1939 -431 873
217 1521 933 1658 1834 24 158 24 472 0 630 -74 480 -282 900 -614 1235 -132
134 -225 211 -369 305 -372 242 -785 363 -1229 359 -82 -1 -186 -6 -230 -11z
m505 -79 c113 -12 285 -49 393 -84 770 -252 1330 -902 1464 -1701 25 -154 25
-524 0 -678 -99 -590 -437 -1114 -930 -1443 -206 -136 -491 -260 -713 -307
l-32 -7 -13 70 c-14 79 -6 101 37 101 30 0 54 -43 38 -68 -8 -12 -33 -31 -52
-38 -1 -1 0 -9 4 -18 9 -22 28 -20 61 8 37 32 39 93 4 130 -34 36 -92 36 -120
0 -24 -30 -26 -61 -9 -133 6 -28 9 -54 5 -57 -16 -16 -229 -36 -387 -36 -354
-1 -629 62 -944 215 -241 117 -410 241 -606 443 -310 320 -505 716 -577 1169
-23 151 -23 469 0 620 57 358 195 690 405 972 78 103 245 279 352 368 174 145
443 297 660 373 301 105 613 138 960 101z"
        />
        <path
          d="M2715 4578 c-3 -18 -11 -75 -19 -125 l-13 -93 23 0 c25 0 24 -3 44
129 5 35 12 66 14 69 9 9 56 -8 76 -28 42 -42 8 -130 -51 -130 -16 0 -29 -4
-29 -9 0 -5 -3 -16 -6 -25 -5 -13 1 -16 28 -16 80 0 139 81 118 159 -13 49
-49 82 -102 92 -71 14 -77 12 -83 -23z"
        />
        <path
          d="M2207 4519 c-25 -15 -50 -68 -43 -93 3 -13 15 -35 27 -49 28 -37 89
-38 124 -2 14 13 25 31 25 40 0 22 -33 19 -48 -5 -17 -28 -58 -26 -78 5 -15
23 -15 27 0 50 16 24 36 32 49 18 4 -3 -2 -17 -14 -29 -14 -15 -17 -24 -9 -29
7 -4 28 9 51 31 l40 39 -22 18 c-23 19 -74 22 -102 6z"
        />
        <path
          d="M1917 4504 c-6 -6 13 -80 39 -151 16 -43 38 -58 68 -47 20 8 17 39
-4 39 -27 0 -53 76 -30 90 17 11 11 35 -9 35 -11 0 -23 9 -26 20 -6 19 -25 26
-38 14z"
        />
        <path
          d="M2095 4491 c-13 -15 -21 -18 -26 -10 -4 7 -14 9 -22 6 -13 -5 -13
-15 2 -84 15 -68 20 -78 40 -81 21 -3 23 -1 17 20 -19 72 -19 95 5 111 18 13
21 20 13 37 l-9 22 -20 -21z"
        />
        <path d="M2480 4480 c0 -6 19 -10 42 -10 23 0 38 3 35 6 -11 11 -77 14 -77 4z" />
        <path
          d="M2968 4461 c-53 -42 -45 -130 14 -150 39 -14 36 -14 40 7 2 9 -5 21
-16 27 -30 16 -43 42 -30 62 18 29 42 39 62 27 18 -12 21 -44 8 -106 -7 -34
-6 -38 12 -38 38 0 57 134 23 164 -31 28 -82 31 -113 7z"
        />
        <path
          d="M1608 4417 c-15 -12 -13 -18 18 -72 18 -33 34 -66 34 -73 0 -7 -30
10 -66 38 -69 51 -110 63 -121 33 -6 -15 94 -223 107 -223 3 0 12 5 20 10 13
8 9 21 -23 86 -21 42 -36 78 -34 80 2 2 37 -21 77 -51 126 -94 145 -72 68 80
-29 58 -55 105 -58 105 -3 -1 -12 -6 -22 -13z"
        />
        <path
          d="M1783 4408 c-28 -13 -47 -60 -39 -94 19 -73 95 -96 147 -45 37 37 38
79 3 120 -28 33 -68 40 -111 19z m87 -47 c14 -27 13 -47 -6 -65 -23 -23 -51
-19 -70 9 -14 21 -15 31 -6 50 15 32 66 35 82 6z"
        />
        <path
          d="M3167 4403 c-8 -10 -25 -42 -37 -72 -17 -43 -18 -56 -8 -62 22 -14
25 -11 49 46 24 59 41 72 63 49 13 -13 13 -20 -4 -60 -24 -55 -25 -70 -5 -78
12 -4 21 7 35 40 11 25 20 49 20 54 0 15 34 21 47 7 12 -12 11 -22 -6 -66 -25
-62 -25 -67 -5 -75 11 -4 21 8 40 49 13 31 24 66 24 79 0 31 -30 56 -68 56
-20 0 -40 9 -56 25 -29 29 -66 32 -89 8z"
        />
        <path
          d="M2240 4314 c-744 -118 -1332 -654 -1509 -1376 -44 -178 -54 -290 -48
-501 9 -344 94 -623 278 -919 255 -411 697 -719 1175 -817 652 -135 1309 87
1764 595 136 152 278 400 349 609 220 654 66 1358 -408 1857 -262 276 -601
463 -977 539 -144 29 -480 36 -624 13z m625 -593 c561 -182 983 -560 1109
-993 162 -558 -127 -1154 -699 -1442 -103 -51 -293 -117 -407 -140 -139 -29
-318 -39 -452 -26 -518 49 -881 253 -970 547 -6 18 16 10 146 -56 323 -163
626 -231 957 -218 566 24 996 311 1147 767 26 79 28 97 29 250 0 146 -3 174
-23 240 -53 167 -134 293 -276 427 -106 101 -200 168 -328 238 l-88 47 0 -756
0 -756 -140 0 -140 0 0 955 c0 525 3 955 8 955 4 0 61 -17 127 -39z m-1492
-209 c45 -33 117 -82 160 -111 l77 -52 0 -599 0 -600 -24 0 c-17 0 -30 11 -45
36 -21 35 -21 45 -21 578 l0 543 -77 51 -78 52 -5 -616 c-5 -653 -4 -634 -50
-634 -45 0 -44 -13 -37 557 4 296 7 609 7 696 0 86 3 157 6 157 3 0 43 -26 87
-58z m1257 -912 l0 -750 -147 0 -148 0 -85 112 c-245 321 -433 567 -497 651
l-73 95 0 256 c0 141 3 256 8 255 4 0 36 -39 73 -87 36 -48 148 -195 249 -327
101 -132 205 -269 232 -304 l48 -64 0 457 0 456 170 0 170 0 0 -750z m-1172
562 l32 -24 0 -474 0 -474 -24 -6 c-13 -3 -34 -1 -45 6 -21 11 -21 14 -21 511
0 397 3 499 13 493 6 -4 27 -19 45 -32z m-16 -1019 c14 -3 34 0 45 5 14 8 23
6 38 -8 12 -11 37 -23 57 -27 l36 -8 -61 -136 -61 -137 -57 1 -56 2 -52 140
c-28 77 -51 142 -51 145 0 3 16 5 35 6 20 0 43 8 53 17 9 10 23 15 32 11 8 -3
27 -8 42 -11z m28 -352 c0 -22 -23 -71 -34 -71 -10 0 -36 52 -36 73 0 4 16 7
35 7 19 0 35 -4 35 -9z"
        />
        <path
          d="M3428 4275 c-54 -30 -57 -113 -6 -149 43 -31 135 -13 107 20 -7 8
-22 11 -40 7 -37 -7 -59 11 -59 48 0 32 29 54 60 44 27 -9 25 -22 -5 -29 -14
-4 -25 -12 -25 -20 0 -10 12 -12 48 -9 26 3 51 9 55 13 13 12 -15 61 -44 76
-35 17 -57 17 -91 -1z"
        />
        <path
          d="M588 3280 c-87 -68 -42 -200 67 -200 83 0 130 84 93 165 -24 53 -113
73 -160 35z"
        />
        <path
          d="M485 2895 c-19 -18 -25 -35 -25 -65 0 -55 34 -90 88 -90 82 0 125 98
67 155 -34 35 -96 35 -130 0z"
        />
        <path
          d="M495 2558 c-28 -16 -35 -28 -35 -65 0 -56 69 -84 110 -43 29 29 26
74 -6 99 -30 24 -41 25 -69 9z"
        />
        <path
          d="M4451 2544 c-12 -15 -21 -35 -21 -46 0 -25 44 -68 70 -68 27 0 70 43
70 70 0 29 -44 70 -74 70 -15 0 -33 -11 -45 -26z"
        />
        <path
          d="M4409 2231 c-31 -31 -37 -67 -17 -104 18 -34 40 -46 85 -47 34 0 46
5 68 32 76 90 -53 202 -136 119z"
        />
        <path
          d="M4294 1886 c-28 -28 -34 -42 -34 -76 0 -34 6 -48 34 -76 28 -28 42
-34 76 -34 57 0 110 50 110 104 0 73 -42 116 -116 116 -28 0 -44 -8 -70 -34z"
        />
        <path
          d="M1960 668 c-6 -24 -18 -65 -26 -92 -24 -83 -18 -100 42 -121 29 -10
41 -10 46 -2 14 22 8 35 -22 46 -36 12 -36 11 -10 103 25 92 25 108 0 108 -14
0 -22 -11 -30 -42z"
        />
        <path
          d="M3130 657 c-53 -27 -62 -98 -18 -144 46 -49 148 -14 148 52 0 33 -14
32 -40 -5 -27 -38 -63 -40 -87 -4 -18 28 -12 48 20 68 24 15 31 1 13 -26 -15
-23 -13 -38 6 -38 4 0 20 19 35 41 26 40 27 42 8 55 -23 17 -52 18 -85 1z"
        />
        <path
          d="M2783 633 c3 -16 11 -72 18 -125 12 -93 14 -98 36 -98 19 0 22 4 19
23 -3 12 -10 56 -16 97 -19 127 -20 130 -42 130 -17 0 -20 -5 -15 -27z"
        />
        <path
          d="M2390 515 l0 -125 25 0 c24 0 25 3 25 55 l0 55 41 0 40 0 -2 -57 c-3
-52 -1 -58 18 -61 20 -3 21 2 25 120 2 78 0 126 -7 130 -24 17 -34 6 -37 -39
-3 -48 -3 -48 -38 -48 -35 0 -35 0 -38 48 -3 42 -5 47 -28 47 l-24 0 0 -125z"
        />
        <path
          d="M2089 564 c-33 -35 -33 -98 0 -125 32 -26 71 -25 71 1 0 13 -7 20
-20 20 -40 0 -50 69 -12 89 20 10 62 -3 63 -21 2 -19 2 -19 -5 -69 -6 -41 -4
-47 13 -52 17 -4 21 2 28 52 4 31 7 65 5 76 -6 56 -100 75 -143 29z"
        />
        <path
          d="M2713 503 c2 -66 1 -68 -23 -68 -23 0 -26 5 -33 63 -7 51 -12 62 -27
62 -18 0 -19 -6 -13 -67 7 -81 22 -103 73 -103 63 0 81 39 64 143 -5 26 -12
37 -24 37 -16 0 -18 -9 -17 -67z"
        />
      </g>
    </svg>
  );
}
