import React from 'react';
import { FlexProps } from '@chakra-ui/react';

import { MotionValue } from 'framer-motion';

import { Flex, Line } from '@/components/atoms';
import { TimeLineNumber } from '@/components/molecules';

interface Props extends FlexProps {
  currentColor: MotionValue<string>;
  pathLength: MotionValue<number>;
}

const numberTop = [120, 580, 1030, 1490];

const scaleAt = [0.18, 0.4, 0.6, 0.83];

export function TimeLine(props: Props) {
  const { currentColor, pathLength, ...rest } = props;

  return (
    <Flex
      display={[`none`, null, null, `flex`]}
      justifyContent="center"
      height="100%"
      width="100%"
      position="absolute"
      top={0}
      left={[-100, null, null, -50, 0]}
      {...rest}
    >
      <Line
        animated
        pathLength={pathLength}
        position="absolute"
        top={0}
        left={257}
        height="95.6%"
        lineFill={currentColor}
      />
      {numberTop.map((top, key) => (
        <TimeLineNumber
          top={top}
          number={key + 1}
          currentColor={currentColor}
          pathLength={pathLength}
          scaleAt={scaleAt[key]}
        />
      ))}
    </Flex>
  );
}
