import React, { forwardRef } from 'react';
import {
  Text as ChakraText,
  TextProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

export interface Props extends TextProps {}

export const Text = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const styles = useStyleConfig(`Text`, props);

  return <ChakraText ref={ref} __css={styles} {...props} />;
});

export const MotionText = motion(Text);
