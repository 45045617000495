import React, { useEffect } from 'react';

import { useAnimation } from 'framer-motion';

import { Box, Conditional, MotionBox, MotionFlex } from '@/components/atoms';

interface Props {
  isOpen: boolean;
  isAnimated: boolean;
}

export function Hamburger(props: Props) {
  const { isOpen, isAnimated } = props;
  const controls = useAnimation();

  const topLineVariant = {
    visible: { x: 20, opacity: 0, transition: { duration: 0.3 } },
    hidden: { x: 0, opacity: 1, transition: { duration: 0.3 } },
  };

  const bottomLineVariant = {
    visible: { x: -20, opacity: 0, transition: { duration: 0.3 } },
    hidden: { x: 0, opacity: 1, transition: { duration: 0.3 } },
  };

  const centerLineVariant = {
    visible: {
      backgroundColor: `white`,
      rotate: 45,
      transition: { delay: 0.2, duration: 0.3 },
    },
    hidden: {
      backgroundColor: isAnimated ? `hsl(214.161, 66%, 41%)` : `white`,
      rotate: 0,
      transition: { duration: 0.3 },
    },
  };

  const centerLineHiddenVariant = {
    visible: {
      backgroundColor: `white`,
      rotate: -45,
      transition: { delay: 0.2, duration: 0.3 },
    },
    hidden: {
      backgroundColor: isAnimated ? `hsl(214.161, 66%, 41%)` : `white`,
      rotate: 0,
      transition: { duration: 0.3 },
    },
  };

  useEffect(() => {
    if (isOpen) {
      controls.start(`visible`);
    } else {
      controls.start(`hidden`);
    }
  }, [isOpen]);

  return (
    <MotionFlex
      width="30px"
      height="30px"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      px={1}
      py={1.5}
    >
      <MotionBox
        width="100%"
        height={1}
        borderRadius="full"
        initial="hidden"
        variants={topLineVariant}
        animate={controls}
        backgroundColor={isAnimated ? `hsl(214.161, 66%, 41%)` : `white`}
      />
      <Conditional in={isAnimated}>
        <Box width="100%" position="relative">
          <MotionBox
            width="100%"
            height={1}
            borderRadius="full"
            backgroundColor="brand.blue"
            initial="hidden"
            variants={centerLineVariant}
            animate={controls}
          />
          <MotionBox
            width="100%"
            height={1}
            borderRadius="full"
            backgroundColor="brand.blue"
            position="absolute"
            top={0}
            initial="hidden"
            variants={centerLineHiddenVariant}
            animate={controls}
          />
        </Box>
      </Conditional>
      <Conditional in={!isAnimated}>
        <Box width="100%" position="relative">
          <MotionBox
            width="100%"
            height={1}
            borderRadius="full"
            backgroundColor="white"
            initial="hidden"
            variants={centerLineVariant}
            animate={controls}
          />
          <MotionBox
            width="100%"
            height={1}
            borderRadius="full"
            position="absolute"
            backgroundColor="white"
            top={0}
            initial="hidden"
            variants={centerLineHiddenVariant}
            animate={controls}
          />
        </Box>
      </Conditional>
      <MotionBox
        width="100%"
        height={1}
        borderRadius="full"
        backgroundColor={isAnimated ? `hsl(214.161, 66%, 41%)` : `white`}
        initial="hidden"
        variants={bottomLineVariant}
        animate={controls}
      />
    </MotionFlex>
  );
}
