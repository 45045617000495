import React from 'react';
import { AnimatePresence } from 'framer-motion';

export const Root = (element: HTMLElement): JSX.Element => {
  return (
    <AnimatePresence initial={false}>
      <>{element.children}</>
    </AnimatePresence>
  );
};
