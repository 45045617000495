import React from 'react';

import {
  Box,
  Button,
  Flex,
  FlexProps,
  Heading,
  Link,
  Text,
} from '@/components/atoms';

import { _Event } from '@/domain';

require(`dayjs/locale/fr`);

export interface Props extends FlexProps {
  event: _Event;
  isInverted?: boolean;
  innerColor?: string;
}

export function Event(props: Props) {
  const {
    innerColor,
    event,
    isInverted = false,
    width,
    height,
    ...rest
  } = props;

  return (
    <Flex
      flexDirection={isInverted ? `column` : `column-reverse`}
      boxSizing="border-box"
      {...rest}
    >
      <Box
        // @ts-ignore
        backgroundImage={`url(${event.mainImage?.images?.fallback?.src})`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        width={width}
        height={height}
        position="relative"
        boxSizing="border-box"
      >
        <Box
          width="100%"
          height="100%"
          position="absolute"
          top={0}
          left={0}
          backgroundColor="rgba(255, 255, 255, 0.3)"
        />
      </Box>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={width}
        height={height}
        boxSizing="border-box"
        px={[5, null, 7]}
        // pt={[3, null, 5]}
        bg={innerColor}
      >
        <Flex alignItems="center" mb={[2, null, 5]}>
          <Box width={6} height={1} bg="white" borderRadius="full" />
          <Heading variant="h2" as="p" color="white" fontWeight={900} mx={5}>
            {event.year}
          </Heading>
          <Box width={6} height={1} bg="white" borderRadius="full" />
        </Flex>
        <Heading variant="h4" color="white" textAlign="center" mb={4}>
          {event.heading}
        </Heading>
        <Text
          size="sm"
          textAlign="center"
          color="white"
          mb={[5, null, null, null, 10]}
        >
          {event.shortDescription.length > 150
            ? `${event.shortDescription?.slice(0, 100)}...`
            : event.shortDescription}
        </Text>
        <Link to={event.buttonPath}>
          <Button variant="white">{event.buttonLabel}</Button>
        </Link>
      </Flex>
    </Flex>
  );
}
