import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '@emotion/react';

import { Box, BoxProps } from '@/components/atoms';

import { colors } from '@/theme/foundations';

type ShapeOneProps = BoxProps & {
  fill?: keyof typeof colors.colors.brand;
  stroke?: keyof typeof colors.colors.brand;
  strokeWidth?: number;
  transform?: string;
};

export function ShapeOne(props: ShapeOneProps) {
  const { width, height, fill, stroke, strokeWidth, transform, ...rest } =
    props;
  const theme = useTheme();
  return (
    <Box width={width || 400} height={height || 400} {...rest}>
      <svg
        viewBox="0 0 500 500"
        width="100%"
        height="100%"
        style={{ transform }}
      >
        <g
          transform="matrix(0.098665, 0, 0, -0.095831, 7.35682, 492.349213)"
          fill="#000000"
        >
          <path
            //@ts-ignore
            fill={theme.colors.brand[fill || `transparent`]}
            //@ts-ignore
            stroke={theme.colors.brand[stroke || `transparent`]}
            strokeWidth={strokeWidth || 200}
            d="M 2029.882 4946.46 C 1966.535 4936.92 1895.509 4923.564 1871.515 4916.885 C 1847.519 4911.162 1811.046 4901.622 1789.931 4895.897 C 1593.171 4844.381 1354.18 4728.945 1113.269 4568.671 C 962.58 4467.545 945.304 4455.143 816.69 4344.477 C 364.622 3957.147 134.269 3489.68 134.269 2961.156 C 134.269 2824.731 155.385 2642.515 186.099 2522.309 C 215.853 2403.057 242.727 2313.38 265.762 2250.415 C 279.199 2214.162 293.596 2171.232 298.395 2155.013 C 307.034 2121.623 355.024 1986.153 383.818 1911.74 C 395.336 1883.119 407.813 1848.774 412.613 1835.419 C 423.17 1802.028 487.477 1636.03 502.834 1601.685 C 510.512 1586.421 533.547 1532.042 554.664 1482.433 C 620.89 1324.067 644.884 1271.596 731.268 1100.828 C 821.489 920.519 864.68 841.335 903.072 781.233 C 916.509 760.244 929.946 738.302 931.866 733.532 C 946.264 696.325 1088.314 506.476 1151.661 439.695 C 1434.804 140.135 1907.987 64.767 2768.932 180.203 C 2994.485 209.777 3147.094 249.846 3431.196 350.018 C 3591.483 407.259 3835.273 523.649 3971.565 607.602 C 4015.717 635.269 4064.666 664.843 4080.024 674.383 C 4205.758 746.888 4429.392 945.323 4536.89 1076.977 C 4683.741 1258.24 4756.685 1416.606 4812.354 1678.007 C 4866.103 1931.774 4866.103 2288.576 4812.354 2589.09 C 4745.168 2955.431 4614.634 3317.957 4453.387 3586.035 C 4427.472 3628.012 4398.679 3670.943 4389.08 3681.437 C 4379.482 3691.93 4356.447 3719.597 4338.21 3743.448 C 4295.02 3799.735 4169.286 3948.561 4128.973 3992.446 C 4001.32 4131.732 3770.006 4346.385 3661.549 4425.568 C 3618.358 4458.005 3593.403 4477.085 3575.166 4495.211 C 3567.488 4500.935 3559.81 4506.66 3556.93 4506.66 C 3554.051 4506.66 3542.533 4514.292 3531.976 4523.832 C 3484.944 4563.901 3295.863 4675.52 3172.048 4734.669 C 2963.771 4836.748 2871.63 4870.139 2682.548 4915.932 C 2496.347 4961.724 2214.164 4974.126 2029.882 4946.46 Z"
          />
        </g>
      </svg>
    </Box>
  );
}

interface ShapeOneAnimatedProps extends ShapeOneProps {
  animatePathLength?: boolean;
  width?: number;
  height?: number;
}

export function ShapeOneAnimated(props: ShapeOneAnimatedProps) {
  const {
    animatePathLength,
    width,
    height,
    fill,
    stroke,
    strokeWidth,
    transform,
    ...rest
  } = props;

  const theme = useTheme();
  return (
    <Box {...rest}>
      <svg
        viewBox="0 0 492.000000 507.000000"
        preserveAspectRatio="xMidYMid meet"
        width={width || 400}
        height={height || 400}
        style={{ transform }}
      >
        <g
          transform="translate(0.000000,507.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <motion.path
            //@ts-ignore
            fill={theme.colors.brand[fill || `transparent`]}
            //@ts-ignore
            stroke={theme.colors.brand[stroke || `transparent`]}
            strokeWidth={strokeWidth || 200}
            d="M1975 5051 c-66 -10 -140 -24 -165 -31 -25 -6 -63 -16 -85 -22 -205
              -54 -454 -175 -705 -343 -157 -106 -175 -119 -309 -235 -471 -406 -711 -896
              -711 -1450 0 -143 22 -334 54 -460 31 -125 59 -219 83 -285 14 -38 29 -83 34
              -100 9 -35 59 -177 89 -255 12 -30 25 -66 30 -80 11 -35 78 -209 94 -245 8
              -16 32 -73 54 -125 69 -166 94 -221 184 -400 94 -189 139 -272 179 -335 14
              -22 28 -45 30 -50 15 -39 163 -238 229 -308 295 -314 788 -393 1685 -272 235
              31 394 73 690 178 167 60 421 182 563 270 46 29 97 60 113 70 131 76 364 284
              476 422 153 190 229 356 287 630 56 266 56 640 0 955 -70 384 -206 764 -374
              1045 -27 44 -57 89 -67 100 -10 11 -34 40 -53 65 -45 59 -176 215 -218 261
              -133 146 -374 371 -487 454 -45 34 -71 54 -90 73 -8 6 -16 12 -19 12 -3 0 -15
              8 -26 18 -49 42 -246 159 -375 221 -217 107 -313 142 -510 190 -194 48 -488
              61 -680 32z"
            initial={{ pathLength: animatePathLength ? 0 : 1 }}
            animate={{
              pathLength: animatePathLength ? 1 : 0,
              transform: [
                `translate(0px, 0px) scale(1)`,
                `translate(-30px, -30px) scale(0.95)`,
                `translate(30px, -300px) scale(1)`,
                `translate(60px, 30px) scale(0.97)`,
                `translate(0px, 0px) scale(1)`,
              ],
              transition: {
                repeat: Infinity,
                times: [0, 0.25, 0.5, 0.75, 1],
                duration: 30,
                ease: `linear`,
              },
            }}
          />
        </g>
      </svg>
    </Box>
  );
}

interface ShapeOneStrokeAnimatedProps extends ShapeOneProps {
  animatePathLength?: boolean;
  duration?: number;
  width?: number;
  height?: number;
}

export function ShapeOneStrokeAnimated(props: ShapeOneStrokeAnimatedProps) {
  const {
    animatePathLength,
    duration,
    width,
    height,
    fill,
    stroke,
    strokeWidth,
    transform,
    ...rest
  } = props;

  const theme = useTheme();
  return (
    <Box {...rest}>
      <svg
        viewBox="0 0 502.000000 650.000000"
        preserveAspectRatio="xMidYMid meet"
        width={width || 400}
        height={height || 400}
        style={{ transform }}
      >
        <g
          transform="translate(0.000000,537.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <motion.path
            //@ts-ignore
            fill={theme.colors.brand[fill || `transparent`]}
            //@ts-ignore
            stroke={theme.colors.brand[stroke || `transparent`]}
            strokeWidth={strokeWidth || 200}
            strokeLinecap="round"
            d="M1975 5051 c-66 -10 -140 -24 -165 -31 -25 -6 -63 -16 -85 -22 -205
              -54 -454 -175 -705 -343 -157 -106 -175 -119 -309 -235 -471 -406 -711 -896
              -711 -1450 0 -143 22 -334 54 -460 31 -125 59 -219 83 -285 14 -38 29 -83 34
              -100 9 -35 59 -177 89 -255 12 -30 25 -66 30 -80 11 -35 78 -209 94 -245 8
              -16 32 -73 54 -125 69 -166 94 -221 184 -400 94 -189 139 -272 179 -335 14
              -22 28 -45 30 -50 15 -39 163 -238 229 -308 295 -314 788 -393 1685 -272 235
              31 394 73 690 178 167 60 421 182 563 270 46 29 97 60 113 70 131 76 364 284
              476 422 153 190 229 356 287 630 56 266 56 640 0 955 -70 384 -206 764 -374
              1045 -27 44 -57 89 -67 100 -10 11 -34 40 -53 65 -45 59 -176 215 -218 261
              -133 146 -374 371 -487 454 -45 34 -71 54 -90 73 -8 6 -16 12 -19 12 -3 0 -15
              8 -26 18 -49 42 -246 159 -375 221 -217 107 -313 142 -510 190 -194 48 -488
              61 -680 32z"
            initial={{ pathLength: animatePathLength ? 0 : 1 }}
            animate={{
              pathLength: animatePathLength ? 1 : 0,
              transition: {
                duration: duration || 0.5,
              },
            }}
          />
        </g>
      </svg>
    </Box>
  );
}
