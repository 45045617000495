import React, { forwardRef } from 'react';
import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';

export interface Props extends SimpleGridProps {}

export const Grid = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    return (
      <SimpleGrid ref={ref} {...props}>
        {props.children}
      </SimpleGrid>
    );
  }
);
