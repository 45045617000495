import { breakpoints } from '@/theme/foundations/breakpoints';

type SizeType = `sm` | `md` | `lg` | `xl` | `2xl`;

const mq = (value: SizeType | number): string | number => {
  if (typeof value === `number`) {
    return `@media (min-width: ${value}px)`;
  }

  const bpArray = Object.keys(breakpoints).map((key) => [
    key,
    // @ts-ignore
    breakpoints[key],
  ]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (value === name) {
      return [...acc, `@media (min-width: ${size}px)`];
    }
    return acc;
  }, []);

  return result;
};

export default mq;
