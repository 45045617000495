import React, { useEffect } from 'react';

import { motion } from 'framer-motion';

import { BoxProps } from '@chakra-ui/react';

import { Box } from '@/components/atoms';

interface Props extends BoxProps {}

export function HorizontalSection(props: Props) {
  const { children, ...rest } = props;

  let isDown = false;
  let startX: any;
  let scrollLeft: any;

  useEffect(() => {
    const slider = document.querySelector(`.event-horizontal-scroll`);
    slider?.addEventListener(`mousedown`, (e) => {
      isDown = true;
      slider.classList.add(`active`);
      //@ts-ignore
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider?.addEventListener(`mouseleave`, () => {
      isDown = false;
      slider.classList.remove(`active`);
    });
    slider?.addEventListener(`mouseup`, () => {
      isDown = false;
      slider.classList.remove(`active`);
    });
    slider?.addEventListener(`mousemove`, (e) => {
      if (!isDown) {
        return;
      }
      e.preventDefault();
      //@ts-ignore
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1.5; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }, []);

  return (
    <Box
      sx={{
        '.event-horizontal-scroll::-webkit-scrollbar': {
          display: `none`,
        },
      }}
      {...rest}
    >
      <motion.div
        style={{
          height: 800,
          overflow: `hidden`,
          position: `relative`,
          transform: `translateZ(0)`,
          cursor: `grab`,
          overflowX: `scroll`,
        }}
        whileTap={{ cursor: `grabbing` }}
        className="event-horizontal-scroll"
      >
        {children}
      </motion.div>
    </Box>
  );
}
