import React from 'react';
import { FlexProps } from '@chakra-ui/react';
import { motion, MotionValue } from 'framer-motion';

import { Flex } from '@/components/atoms';

interface Props extends FlexProps {
  animated: boolean;
  pathLength: MotionValue<number>;
  lineFill?: MotionValue<string>;
}

export function Line(props: Props) {
  const { lineFill, pathLength, ...rest } = props;

  return (
    <Flex borderRadius="xl" overflow="hidden" {...rest}>
      <svg
        id="eCyubczdB571"
        viewBox="0 0 10 480"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        style={{ height: `100%` }}
      >
        <motion.path
          d="M-30 0, L30 0"
          transform="matrix(-.002191 9.581141-1-.000229 4.947425 240)"
          strokeWidth={10}
          width="100%"
          height="100%"
          style={{
            pathLength,
            stroke: lineFill,
            // transition: `0.1s`,
          }}
        />
      </svg>
    </Flex>
  );
}
