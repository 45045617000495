import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  container: {
    border: `none`,
    my: 2,
    maxWidth: 500,
  },
  button: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderTopWidth: 4,
    borderLeftWidth: 4,
    borderRightWidth: 4,
    borderColor: `transparent`,
    // bg: `brand.blue`,
    color: `white`,
    py: 1,
    px: 2,
    // _hover: {
    //   bg: `brand.blue`,
    // },
  },
  panel: {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomWidth: 4,
    borderLeftWidth: 4,
    borderRightWidth: 4,
    borderColor: `transparent`,
    // bg: `brand.blue`,
    py: 1,
    px: 2,
  },
};

const defaultProps: SystemStyleObject = {};

export const Accordion = {
  parts: [`container`, `item`, `button`, `panel`],
  baseStyle,
  defaultProps,
};
