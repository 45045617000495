import React, { forwardRef } from 'react';
import { Flex as ChakraFlex, FlexProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';

export interface Props extends FlexProps {}

export const Flex = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    return (
      <ChakraFlex ref={ref} {...props}>
        {props.children}
      </ChakraFlex>
    );
  }
);

export const MotionFlex = motion(Flex);
