import React, { useRef } from 'react';
import { FlexProps } from '@chakra-ui/react';

import { useInView } from 'framer-motion';

import {
  AnimatedBox,
  Box,
  Flex,
  Heading,
  Image,
  ShapeOne,
  Text,
} from '@/components/atoms';

import { getBackgroundColor } from '@/utils';

import { _SchoolProject } from '@/domain';

interface Props extends FlexProps {
  index: number;
  project: _SchoolProject;
}

export function SchoolProject(props: Props) {
  const { index, project, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { amount: 0.3, once: true });

  return (
    <Box ref={ref} key={index} mb={[100, null, null, 200]}>
      <Flex
        flexDirection={[
          `column`,
          null,
          index % 2 === 0 ? `row` : `row-reverse`,
        ]}
        justifyContent="space-between"
        alignItems={[`center`, null, `flex-start`]}
        {...rest}
      >
        <Flex
          width={[270, 350, 450, 450]}
          height={[180, 230, 320, 320]}
          position="relative"
          justifyContent="center"
          mr={[0, null, 10]}
          mb={[20, null, 0]}
        >
          <AnimatedBox animate={isInView} scaleHidden={0.9} yHidden={0.1}>
            <Image
              image={project.image}
              alt={project.image.alt}
              borderRadius="lg"
              objectFit="cover"
              width={[270, 350, 450, 450]}
              height={[180, 230, 320, 320]}
            />
          </AnimatedBox>
        </Flex>
        <Flex
          width={[`100%`, null, `50%`]}
          flexDirection="column"
          alignItems={[`center`, null, `flex-start`]}
          pr={[0, null, 10]}
          position="relative"
        >
          <AnimatedBox
            animate={isInView}
            direction={index % 2 === 0 ? `left` : `right`}
          >
            <Heading variant="h2" mb={5} textAlign={[`center`, null, `left`]}>
              {project.heading}
            </Heading>
          </AnimatedBox>
          <AnimatedBox
            animate={isInView}
            direction={index % 2 === 0 ? `left` : `right`}
            delay={0.3}
          >
            <Text mb={10} textAlign={[`center`, null, `left`]}>
              {project.description}
            </Text>
          </AnimatedBox>
          <AnimatedBox
            animate={isInView}
            yHidden={0.1}
            scaleHidden={0.1}
            delay={0.5}
            duration={0.3}
            position="absolute"
            top={index % 2 === 0 ? [0, null, null, -50] : [0, null, null, -70]}
            right={index % 2 === 0 ? [-200, null, null, -250] : `initial`}
            left={index % 2 !== 0 ? [-200, null, null, -250] : `initial`}
            zIndex={-1}
          >
            <ShapeOne
              width={[400, null, null, 500]}
              height={[400, null, null, 500]}
              fill={getBackgroundColor(index)}
            />
          </AnimatedBox>
        </Flex>
      </Flex>
      <AnimatedBox
        animate={isInView}
        direction={index % 2 === 0 ? `left` : `right`}
        delay={0.3}
        w="100%"
        mt={4}
      >
        <Text mb={10} textAlign={[`center`, null, `left`]} w="100%">
          {project.secondDescription}
        </Text>
      </AnimatedBox>
    </Box>
  );
}
