import React from 'react';

import { Box, Flex, FlexProps, SelectComponent } from '@/components/atoms';

export interface Props extends FlexProps {
  years: SelectedYear[];
  selectedYear: SelectedYear;
  onSelectYear: (year: SelectedYear) => void;
}

type SelectedYear = {
  label: string;
  value: string;
};

export function FilterSelect(props: Props) {
  const { years, onSelectYear, selectedYear, ...rest } = props;

  return (
    <Flex py={10} {...rest}>
      <Box width={[250, null, 300]}>
        <SelectComponent
          options={years}
          value={selectedYear}
          //@ts-ignore
          onChange={onSelectYear}
        />
      </Box>
    </Flex>
  );
}
