import React, { useContext } from 'react';
import styled from '@emotion/styled';

import CustomCursorContext from './context/CustomCursorContext';

const MainCursor = styled.div`
  z-index: 10000;
  pointer-events: none;

  /* overflow: hidden; */
  transform: translate3d(0, 0, 0);
  position: fixed;
  transition: opacity 1s cubic-bezier(0.77, 0, 0.175, 1);
  animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
  mix-blend-mode: normal;

  .slider-drag {
    .cursor-background {
      animation: scaleUp 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards,
        bgUp 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;

      &:after {
        animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards,
          translateRightDot 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
      }

      &:before {
        animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards,
          translateLeftDot 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
      }
    }

    .main-cursor {
      animation: fadeOut 0.75s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
    }
  }

  .hamburger {
    .cursor-background {
      animation: fadeOut 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
    }

    .main-cursor-background {
      animation: scaleUp 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes bgUp {
    from {
      background-color: transparent;
    }

    to {
      background-color: black;
    }
  }

  @keyframes bgDown {
    from {
      background-color: black;
    }

    to {
      background-color: transparent;
    }
  }

  @keyframes scaleUp {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.5);
    }
  }

  @keyframes scaleDown {
    from {
      transform: scale(1.5);
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes translateLeftDot {
    from {
      transform: translate(20px, -50%);
    }

    to {
      transform: translate(0px, -50%);
    }
  }

  @keyframes translateRightDot {
    from {
      transform: translate(-20px, -50%);
    }

    to {
      transform: translate(0px, -50%);
    }
  }
`;

const MainCursorBackground = styled.div`
  width: 7px;
  height: 7px;
  /* background: #de93a3; */
  background: ${({ theme }) => theme.colors.brand[`orange`]};
  border-radius: 50%;
`;

const SecondaryCursor = styled.div`
  width: 35px;
  height: 35px;
  z-index: 10000;
  pointer-events: none;
  /* overflow: hidden; */
  transform: translate3d(0, 0, 0);
  position: fixed;
`;

const CursorBackground = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* border: 1px solid #de93a3; */
  border: 1px solid ${({ theme }) => theme.colors.brand[`orange`]};
  position: relative;

  &:after,
  &:before {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: white;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    animation: fadeOut 0.75s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
  }

  &:before {
    left: 10px;
  }

  &:after {
    right: 10px;
  }
`;

export const CustomCursor = (): JSX.Element => {
  const { type } = useContext(CustomCursorContext);
  const secondaryCursor = React.useRef(null);
  const mainCursor = React.useRef(null);
  const positionRef = React.useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  });

  React.useEffect(() => {
    document.addEventListener(`mousemove`, (event) => {
      const { clientX, clientY } = event;

      const mouseX = clientX;
      const mouseY = clientY;

      if (
        secondaryCursor.current &&
        secondaryCursor.current.clientWidth &&
        secondaryCursor.current.clientHeight &&
        mainCursor.current &&
        mainCursor.current.style
      ) {
        positionRef.current.mouseX =
          mouseX - secondaryCursor.current.clientWidth / 2;
        positionRef.current.mouseY =
          mouseY - secondaryCursor.current.clientHeight / 2;
        mainCursor.current.style.transform = `translate3d(${
          mouseX - mainCursor.current.clientWidth / 2
        }px, ${mouseY - mainCursor.current.clientHeight / 2}px, 0)`;
      }
    });
    return () => {};
  }, []);

  React.useEffect(() => {
    const followMouse = () => {
      positionRef.current.key = requestAnimationFrame(followMouse);
      const {
        mouseX,
        mouseY,
        destinationX,
        destinationY,
        distanceX,
        distanceY,
      } = positionRef.current;
      if (!destinationX || !destinationY) {
        positionRef.current.destinationX = mouseX;
        positionRef.current.destinationY = mouseY;
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
        if (
          Math.abs(positionRef.current.distanceX) +
            Math.abs(positionRef.current.distanceY) <
          0.1
        ) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.destinationX += distanceX;
          positionRef.current.destinationY += distanceY;
        }
      }
      if (secondaryCursor.current && secondaryCursor.current.style) {
        secondaryCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
      }
    };
    followMouse();
  }, []);
  return (
    <div className={`cursor-wrapper ${type}`}>
      <MainCursor ref={mainCursor}>
        <MainCursorBackground className="main-cursor-background"></MainCursorBackground>
      </MainCursor>
      <SecondaryCursor className="secondary-cursor" ref={secondaryCursor}>
        <CursorBackground className="cursor-background"></CursorBackground>
      </SecondaryCursor>
    </div>
  );
};

export default CustomCursor;
