import React, { useEffect, useRef } from 'react';

// @ts-ignore
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// @ts-ignore
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

import { FlexProps } from '@chakra-ui/react';

import { Box, Flex } from '@/components/atoms';

import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = `${process.env.GATSBY_MAPBOX_TOKEN}`;

interface Props extends FlexProps {
  center?: number[];
  zoom?: number;
}

export const Mapbox = (props: Props) => {
  const { center, zoom } = props;

  const mapContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current as HTMLElement | string,
      style: `mapbox://styles/mapbox/streets-v11?optimize=true`,
      center: center || [4.12717848009263, 50.725686101764204],
      zoom: zoom || 13,
      minZoom: 6,
      maxZoom: 17,
      'marker-color': `#FFF`,
      pitch: 50,
    });
    map.on(`wheel`, (event: any) => {
      if (event.originalEvent.ctrlKey) {
        return;
      }

      if (event.originalEvent.metaKey) {
        return;
      }

      if (event.originalEvent.altKey) {
        return;
      }

      event.preventDefault();
    });
    new mapboxgl.Marker()
      .setLngLat([4.490607569336505, 50.73031954977133])
      .addTo(map);

    map.addControl(new mapboxgl.NavigationControl());
    return () => map.remove();
  }, [zoom, center]);

  return (
    <Box {...props}>
      <Flex
        h="100%"
        w="100%"
        sx={{
          '.mapboxgl-marker svg, path': {
            width: `50px`,
            height: `50px`,
            fill: `hsl(3, 67%, 63%)`,
          },
        }}
      >
        <Box w="100%" h="100%" className="map-container" ref={mapContainer} />
      </Flex>
    </Box>
  );
};
