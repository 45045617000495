import React, { useMemo, useRef } from 'react';

import { useInView } from 'framer-motion';

import { useBreakpointValue } from '@chakra-ui/react';

import {
  Box,
  Button,
  Flex,
  FlexProps,
  Heading,
  Image,
  Link,
  MotionFlex,
  Text,
} from '@/components/atoms';

import { _HomeProject } from '@/domain';

export interface Props extends FlexProps {
  backgroundColor: string;
  project: _HomeProject;
}

export function HomeProjectMobile(props: Props) {
  const { backgroundColor, project, ...rest } = props;
  const buttonColor = backgroundColor.split(`.`)?.[1];

  const ref = useRef<HTMLDivElement>(null);
  const isLargerThanLg = useBreakpointValue({
    base: false,
    lg: true,
  });
  const isInView = useInView(ref, { once: true, amount: 0.5 });

  const variants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        x: {
          duration: 0.3,
        },
      },
    },
    hidden: {
      opacity: 0,
      x: 100,
      transition: {
        x: {
          duration: 0.3,
        },
      },
    },
  };

  const animate = useMemo(() => {
    if (!isLargerThanLg) {
      if (isInView) {
        return `visible`;
      } else {
        return `hidden`;
      }
    }
  }, [isLargerThanLg, isInView]);

  return (
    //@ts-ignore
    <MotionFlex
      ref={ref}
      display={[`flex`, null, null, `none`]}
      flexDirection="column"
      justifyContent="flex-start"
      width="100%"
      height={[580, 440, 480, 380, 380]}
      borderRadius="xl"
      overflow="hidden"
      borderWidth="4px"
      borderColor={backgroundColor}
      backgroundColor={backgroundColor}
      variants={variants}
      initial="hidden"
      animate={animate}
      {...rest}
    >
      <Box textAlign="center" py={6}>
        <Heading variant="h5" textAlign="center" color="white" height="100%">
          {project.heading}
        </Heading>
      </Box>
      <Flex
        className="home_project_text_container"
        flexDirection={[`column`, null, null, `row`]}
        justifyContent={[`center`, null, null, `space-between`]}
        alignItems="center"
        height="100%"
        bg="white"
        borderBottomRadius="lg"
        transition="0.3s"
        px={4}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          className="home_project_icons"
          mr={[0, null, null, 2]}
          p={5}
          borderRadius="full"
          backgroundColor={backgroundColor}
          transition="0.3s"
          position="relative"
          mb={[5, null, null, 0]}
        >
          <Image
            className="home_project_icon"
            image={project.icon}
            alt={project.icon.alt}
            width={50}
            height={50}
          />
          <Image
            className="home_project_icon_coloured"
            image={project.iconColoured}
            alt={project.iconColoured.alt}
            width={50}
            height={50}
            position="absolute"
            top={5}
            left={5}
            opacity={0}
          />
        </Flex>
        <Flex
          flexDirection="column"
          alignItems={[`center`, null, null, `flex-start`]}
          width="100%"
          ml={3}
        >
          <Text
            className="home_project_text"
            color="black"
            size="lg"
            fontWeight="semibold"
            textAlign={[`center`, null, null, `left`]}
            transition="0.3s"
            mb={4}
          >
            {project.text}
          </Text>
          <Box position="relative" className="home_project_buttons">
            <Box position="absolute" opacity={0} transition="0.3s">
              <Link to={project.buttonPath}>
                <Button variant="white">{project.buttonLabel}</Button>
              </Link>
            </Box>
            <Box transition="0.3s">
              <Link to={project.buttonPath}>
                <Button
                  variant={buttonColor}
                  className="home_project_hidden_button"
                  zIndex={2}
                >
                  {project.buttonLabel}
                </Button>
              </Link>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </MotionFlex>
  );
}
