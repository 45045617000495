import React, { forwardRef } from 'react';
import { Box as ChakraBox, BoxProps, useStyleConfig } from '@chakra-ui/react';
import { motion } from 'framer-motion';

export interface Props extends BoxProps {}

export const Box = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const styles = useStyleConfig(`Box`, props);

    return <ChakraBox ref={ref} __css={styles} {...props} />;
  }
);

export const MotionBox = motion(Box);
