exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-association-parents-tsx": () => import("./../../../src/pages/association-parents.tsx" /* webpackChunkName: "component---src-pages-association-parents-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-ecole-tsx": () => import("./../../../src/pages/ecole.tsx" /* webpackChunkName: "component---src-pages-ecole-tsx" */),
  "component---src-pages-equipe-tsx": () => import("./../../../src/pages/equipe.tsx" /* webpackChunkName: "component---src-pages-equipe-tsx" */),
  "component---src-pages-evenements-tsx": () => import("./../../../src/pages/evenements.tsx" /* webpackChunkName: "component---src-pages-evenements-tsx" */),
  "component---src-pages-extra-scolaire-tsx": () => import("./../../../src/pages/extra-scolaire.tsx" /* webpackChunkName: "component---src-pages-extra-scolaire-tsx" */),
  "component---src-pages-galerie-tsx": () => import("./../../../src/pages/galerie.tsx" /* webpackChunkName: "component---src-pages-galerie-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infos-pratiques-tsx": () => import("./../../../src/pages/infos-pratiques.tsx" /* webpackChunkName: "component---src-pages-infos-pratiques-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/Event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */)
}

