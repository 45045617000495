import React, { ReactNode } from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { Box, Text } from '@/components/atoms';

export interface Props extends AccordionProps {
  heading: ReactNode;
  body: ReactNode;
  innerColor?: string;
}

export function Accordion(props: Props) {
  const { heading, body, innerColor, ...rest } = props;

  const styles = useMultiStyleConfig(`Accordion`, props);
  return (
    <ChakraAccordion
      allowMultiple
      width={[250, 300, 500]}
      __css={styles.container}
      {...rest}
    >
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              __css={styles.button}
              sx={{
                transition: `0.1s`,
                borderBottomLeftRadius: isExpanded ? 0 : 10,
                borderBottomRightRadius: isExpanded ? 0 : 10,
                borderBottomWidth: isExpanded ? 0 : 4,
              }}
              bg={innerColor}
              _hover={{ bg: innerColor }}
            >
              <Box
                w="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text size="xl" fontWeight={500}>
                  {heading}
                </Text>
                <Box
                  transition="0.3s"
                  transform={isExpanded ? `rotate(-180deg)` : `rotate(0deg)`}
                >
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="lg"
                    color="white"
                  />
                </Box>
              </Box>
            </AccordionButton>
            <AccordionPanel
              pb={4}
              color="white"
              __css={styles.panel}
              sx={{ p: { mb: 3 } }}
              bg={innerColor}
            >
              {body}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </ChakraAccordion>
  );
}
