import React, { ReactNode } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';

import { Box, BoxProps, Container } from '@/components/atoms';
import { Footer, MobileNavigation, Navigation } from '@/components/organisms';

import { _Navigation, GatsbyPrismicLayout } from '@/domain';

interface StaticQuery {
  layout: GatsbyPrismicLayout;
}

interface Props extends BoxProps {
  children: ReactNode;
  withContainer?: boolean;
  isHome?: boolean;
}

export const Layout = (props: Props) => {
  const { isHome, children, withContainer } = props;
  const data = useStaticQuery<StaticQuery>(navigationQuery);

  const navigation = data.layout.data.navigation as _Navigation[];

  const isLargerThanMd = useBreakpointValue({ base: false, md: true });

  const renderBody = (withContainer: boolean = false) => {
    const body = (
      <Box as="main" bg="transparent">
        {children}
      </Box>
    );
    if (withContainer) {
      return <Container>{body}</Container>;
    }
    return body;
  };
  return (
    <>
      {/*{isLargerThanMd && <CustomCursor />}*/}
      <Navigation isHome={isHome} navigation={navigation} />
      <MobileNavigation navigation={navigation} />
      <motion.main
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        transition={{
          type: `spring`,
          mass: 0.35,
          stiffness: 75,
          duration: 1,
        }}
      >
        {renderBody(withContainer)}
      </motion.main>
      <Footer />
    </>
  );
};

export const navigationQuery = graphql`
  {
    layout: prismicLayout {
      data {
        navigation {
          label
          path
        }
      }
    }
  }
`;
