import React from 'react';

import { Root } from './src/components/organisms';

export const wrapPageElement = ({ element, props }) => {
  return (
    <Root {...props}>
      <div style={{ minWidth: `320px` }}>{element}</div>
    </Root>
  );
};
