import React, { useRef } from 'react';
import { FlexProps } from '@chakra-ui/react';

import { useInView } from 'framer-motion';

import {
  Box,
  Conditional,
  Flex,
  Heading,
  MotionFlex,
  Text,
} from '@/components/atoms';

import { _Teacher } from '@/domain';

export interface Props extends FlexProps {
  teacher: _Teacher;
}

export function HomeTeacher(props: Props) {
  const { teacher, ...rest } = props;
  const ref = useRef(null);

  const isInView = useInView(ref, { amount: 0.5, once: true });

  return (
    <Flex
      ref={ref}
      width="100%"
      height="100%"
      borderWidth={5}
      borderColor="brand.white"
      borderRadius="lg"
      flexDirection="column"
      alignItems="center"
      bg="rgba(255, 255, 255, 0.7)"
      backdropFilter="blur(10px)"
      boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
      px={5}
      py={[10, 5, null, 10]}
      zIndex={10}
      transition="0.3s"
      {...rest}
    >
      <MotionFlex
        justifyContent="center"
        alignItems="center"
        width={130}
        height={130}
        borderRadius="full"
        borderWidth={5}
        borderColor="brand.white"
        overflow="hidden"
        mb={10}
        backgroundImage={
          //@ts-ignore
          teacher.image?.gatsbyImageData !== null
            ? //@ts-ignore
              `url(${teacher.image.gatsbyImageData.images.fallback.src})`
            : ``
        }
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        animate={{
          scale: isInView ? 1 : 0.7,
          opacity: isInView ? 1 : 0,
        }}
      >
        {/*//@ts-ignore*/}
        <Conditional in={teacher.image?.gatsbyImageData === null}>
          <Box>
            <Heading variant="h4">
              {teacher.name.length > 0 ? teacher.name?.charAt(0) : ``}
            </Heading>
          </Box>
        </Conditional>
      </MotionFlex>
      <Flex flexDirection="column" alignItems="center">
        <Box mb={4}>
          <Text textAlign="center" fontWeight="semibold" size="xl">
            {teacher.name}
          </Text>
        </Box>
        <Box mb={4}>
          <Text textAlign="center" fontWeight="semibold">
            {teacher.function}
          </Text>
        </Box>
        <Box>
          <Text textAlign="center">{teacher.description}</Text>
        </Box>
      </Flex>
    </Flex>
  );
}
